import React from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component';
import Layout from '../components/layout'
import {getArticleLinks} from "../utils/articleUtil";
import {Title} from "../components/SEO";
import {groupBy as _groupBy, orderBy as _orderBy} from 'lodash';
import CategoryTitleBordered from "../components/helpers/categoryTitleBordered";

const ArticlePreview = loadable(() => import("./articlePreview"));

const CategoryPage = props => {
    const topLevelArticles = [];
    const getArticlesSubCategoryWise = () => {
        const pillarArticles = _groupBy(props.data.pillarArticles.nodes, (article) => {
          const subCategory = article.category.find(item => !!item.treeParent);
          return subCategory.title;
        });
        const obj = _groupBy(props.data.subCategoryArticles.nodes, (article) => {
            if (topLevelArticles.includes(article.id) || article.isCategoryPinned) {
                return "included";
            }
            /*if (props.pageContext.newsArticles.length < 3 && !props.pageContext.newsArticles.find(obj => obj.id === article.id)) {
                props.pageContext.newsArticles.push(article);
                //return "included";
            }*/
            const subCategory = article.category.find(item => !!item.treeParent);
            return subCategory.title;
        });
        delete obj.included;
        const res = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (!obj[key].length) {
                    return;
                }
                const articles = [];
                if (pillarArticles[key] && pillarArticles[key].length) {
                  articles.push(pillarArticles[key][0]);
                }
                res.push({
                  category: key,
                  data: articles.concat(obj[key])
                });
            }
        }
        //props.pageContext.newsArticles = _orderBy(props.pageContext.newsArticles, ['publishedAt'], ['desc']);
        return res;
    }
    const subCategoryArticles = getArticlesSubCategoryWise();
    const subCategoryArticlesRow1 = subCategoryArticles.slice(0, 3);
    const subCategoryArticlesRows = subCategoryArticles.slice(3).chunk(3);

    const excludeAlreadyDisplayed = (articles) => {
        return articles.filter(article => !topLevelArticles.includes(article.id));
    }

    return (
        <Layout path={props.location.pathname}>
            <Title title={props.pageContext.title}/>
            <main className="wrapper font-arial leading-snug" data-datocms-noindex>
              <CategoryTitleBordered className={"uppercase"} title={props.pageContext.title} titleClass={"font-arial"}/>
              <div className={"lg:grid lg:grid-cols-3 lg:gap-2 pt-4"}>
                {
                  props.data.subCategoryArticles.nodes.slice(0, 3).map((article) => {
                    topLevelArticles.push(article.id);
                    return <ArticlePreview key={article.slug}
                                           data={article}
                                           className={"lg:col-span-1 mb-6 lg:mb-2 pb-2"}
                                           headingClass={`text-xl lg:text-2xl header-separator font-bold`}
                                           imageClass={"border-4 border-denim"}
                                           textClass={"mt-4 text-lg text-text-600"}
                                           fullImage={true}
                                           noFlag={true}
                                           noAuthor={true}
                                           wrappedImg={true}
                                           noReadMore={true}/>
                  })
                }
              </div>
              <div className={"lg:flex"}>
                <div>
                  <div className={"lg:flex mt-8 mb-8"}>
                    <div className={"grid grid-cols-3 gap-6"}>
                      {
                        subCategoryArticlesRow1.map((obj) => {
                          const slug = getArticleLinks(obj.data[0]).categoryPath();
                          return <div
                            className={`col-span-3 lg:col-span-1`}
                            key={slug}>
                            <CategoryTitleBordered className={"mt-0"} link={slug} title={obj.category} borderClass={"lg:hidden"} titleClass={"text-xl font-arial"}/>
                            {obj.data.slice(0, 1).map((article, i) => {
                              topLevelArticles.push(article.id);
                              return <ArticlePreview
                                key={article.slug}
                                data={article}
                                headingClass={"text-xl mb-0 font-semibold"}
                                imageClass={"border-4 border-denim"}
                                textClass={"mt-2 text-text-600"}
                                noFlag={true}
                                noAuthor={true}
                                wrappedImg={true}
                                noReadMore={true}
                                noBody={true}
                                noImage={i !== 0}/>
                            })}
                            {obj.data.slice(1, 5).map((article, i) => {
                              topLevelArticles.push(article.id);
                              return <CategoryTitleBordered className={"top-border-category mobile-border b-h-100 my-6 relative ml-0"} link={`${slug}/${article.slug}`} title={article.heading} borderClass={"hidden"} titleClass={"hover:underline text-xl text-black normal-case max-w-full font-arial font-semibold bg-transparent hover:underline"}/>
                            })}
                          </div>
                        })
                      }
                    </div>
                  </div>
                  {
                    subCategoryArticlesRows.map((data, index) => {
                      return <div key={`row-${index}`}>
                        <div className={"grid grid-cols-3 gap-6 mb-4"}>
                          {
                            data.map((obj) => {
                              const slug = getArticleLinks(obj.data[0]).categoryPath();
                              return <div
                                className={`col-span-3 lg:col-span-1`}
                                key={slug}>
                                <CategoryTitleBordered link={slug} title={obj.category} borderClass={"lg:hidden"} titleClass={"text-xl font-arial"}/>
                                {obj.data.slice(0, 1).map((article, i) => {
                                  topLevelArticles.push(article.id);
                                  return <ArticlePreview
                                    key={article.slug}
                                    data={article}
                                    headingClass={"text-xl mb-0 font-semibold"}
                                    imageClass={"border-4 border-denim"}
                                    textClass={"mt-2 text-text-600"}
                                    noFlag={true}
                                    noAuthor={true}
                                    wrappedImg={true}
                                    noReadMore={true}
                                    noBody={true}
                                    noImage={i !== 0}/>
                                })}
                                {obj.data.slice(1, 5).map((article, i) => {
                                  topLevelArticles.push(article.id);
                                  return <CategoryTitleBordered className={"top-border-category mobile-border b-h-100 my-6 relative ml-0"} link={`${slug}/${article.slug}`} title={article.heading} borderClass={"hidden"} titleClass={"hover:underline text-xl text-black normal-case max-w-full font-arial font-semibold bg-transparent hover:underline"}/>
                                })}
                              </div>
                            })
                          }
                        </div>
                      </div>
                    })
                  }
                </div>
                <div className={"hidden lg:block ml-4"} style={{minWidth: "320px"}}></div>
              </div>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query ArticlesCategoryWise($subCategories: [String]) {
        pillarArticles: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $subCategories}}}, website: {elemMatch: {name: {eq: "at"}}}, isCategoryPinned: {eq: true}}
            sort: {order: DESC, fields: publishedAt}
        ) {
            nodes {
                ...ArticleFieldsPlugin    
            }
        }
        subCategoryArticles: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $subCategories}}}, website: {elemMatch: {name: {eq: "at"}}}}
            sort: {order: DESC, fields: publishedAt}
            limit: 100
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`

export default CategoryPage
